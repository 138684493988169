import { Button, Icon } from '@sb/design-system';
import { WidgetView } from '@sbrc/components/visualizer-view-shared/widget-panel';

interface RecoveryIntroViewProps {
  onStart: () => void;
  introMessage: React.ReactNode;
}

export function RecoveryIntroView({
  onStart,
  introMessage,
}: RecoveryIntroViewProps) {
  return (
    <WidgetView className="tw-p-16 tw-gap-16">
      <Icon
        kind="exclamationTriangle"
        className="tw-text-mango tw-mx-auto tw-icon-42"
      />

      <div>
        <h3 className="tw-font-medium tw-text-center">
          Manual recovery required.
        </h3>

        {introMessage}

        <p className="tw-text-15 tw-mt-8 tw-text-center">
          To successfully recover, each joint needs to be within joint limits.
          Torque should be below the threshold defined for collisions and the
          brake should be disengaged.
        </p>

        <p className="tw-text-15 tw-mt-8 tw-text-center">
          In the next step, jog the available joints to recover the robot.{' '}
        </p>
      </div>

      <Button variant="Filled" onClick={onStart} className="tw-rounded-6">
        Start recovery
      </Button>
    </WidgetView>
  );
}
