import { useState } from 'react';

import { Button } from '@sb/design-system';
import {
  useRobotJointConnectionState,
  useRobotSafeguardState,
  useRobotUnbrake,
} from '@sbrc/hooks';

import { ConfirmNoHumansAndPayload } from './ConfirmNoHumansAndPayload';

interface UnbrakeButtonProps {
  isVizbot: boolean;
}

export function UnbrakeButton({ isVizbot }: UnbrakeButtonProps) {
  const [isNoHumansConfirmed, setIsNoHumansConfirmed] = useState(false);

  const { unbrakeRobot, canUnbrake } = useRobotUnbrake({
    isVizbot,
  });

  const isEstopped = useRobotSafeguardState({ isVizbot }) === 'eStop';

  const connectionStates = useRobotJointConnectionState({ isVizbot });

  const allJointsReady = connectionStates.every((state) => state === 'ready');

  return (
    <>
      {!isVizbot && (
        <ConfirmNoHumansAndPayload
          isConfirmed={isNoHumansConfirmed}
          setIsConfirmed={setIsNoHumansConfirmed}
        />
      )}

      <Button
        disabled={!canUnbrake(isNoHumansConfirmed)}
        onClick={unbrakeRobot}
        variant="Filled"
        data-testid="warning-widget-unbrake-button"
        className="tw-rounded-6 tw-mt-4"
      >
        {allJointsReady || isEstopped ? 'Unbrake' : 'Initializing Joints'}
      </Button>

      {isEstopped && (
        <p className="tw-text-15 tw-text-center tw-text-red">
          All hardware E-Stop buttons must be reset before the robot can be
          unbraked.
        </p>
      )}
    </>
  );
}
