import { useRouter } from 'next/router';

import { Button, Typography } from '@sb/ui/components';
import { OpenIcon } from '@sb/ui/icons';
import { LINK_TO_SCENE_PAGE } from '@sbrc/utils';

const GoToSceneViewButton = () => {
  const { push } = useRouter();

  const onGoToSceneViewClick = () => {
    push(LINK_TO_SCENE_PAGE);
  };

  return (
    <Button
      isFullWidth
      onClick={onGoToSceneViewClick}
      endIcon={<OpenIcon />}
      variant="grayDark"
    >
      <Typography variant="medium">Go to Routine Visualizer</Typography>
    </Button>
  );
};

export default GoToSceneViewButton;
