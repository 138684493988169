import {
  Button,
  Gauge,
  GaugeContainer,
  HoldableButton,
  Icon,
} from '@sb/design-system';
import type { ArmJointVelocities, JointNumber } from '@sb/motion-planning';
import type { SpeedProfile } from '@sb/routine-runner';
import { RECOVERY_SPEED_PROFILE } from '@sb/routine-runner';
import {
  useGuidedMode,
  useRoutineRunnerHandle,
  useUnbrakeJoint,
} from '@sbrc/hooks';
import { JOINT_NAMES } from '@sbrc/utils';

import { TorqueGauge } from './TorqueGauge';

interface JointRowProps {
  isVizbot: boolean;
  isDisabled: boolean;
  jointNumber: JointNumber;
  isBraked?: boolean;
  jointAngle?: number;
  min?: number;
  max?: number;
  disturbance?: number;
  collisionThreshold: number;
  maxTorque: number;
}

export function JointRow({
  isVizbot,
  isDisabled,
  jointNumber,
  isBraked,
  jointAngle,
  min,
  max,
  disturbance,
  collisionThreshold,
  maxTorque,
}: JointRowProps) {
  const routineRunnerHandle = useRoutineRunnerHandle({ isVizbot });
  const { runAdHocCommand, stopGuidedMode } = useGuidedMode({ isVizbot });
  const { unbrakeJoint } = useUnbrakeJoint({ isVizbot });

  const onHoldSpinButton = (isPositive: boolean) => {
    const jointRelativeSpeedProfile: SpeedProfile = {
      ...RECOVERY_SPEED_PROFILE,
      maxJointSpeeds: RECOVERY_SPEED_PROFILE.maxJointSpeeds.map(
        (jointSpeed) => {
          // sign reflects direction
          return jointSpeed * (isPositive ? 1 : -1);
        },
      ) as ArmJointVelocities,
    };

    const handleJointAngleChange = async (): Promise<void> => {
      return routineRunnerHandle.moveJointRelative(
        jointNumber,
        jointRelativeSpeedProfile,
      );
    };

    runAdHocCommand({ onRunCommand: handleJointAngleChange });
  };

  const getIcon = () => {
    switch (isBraked) {
      case true:
        return <Icon kind="exclamationBrake" className="tw-text-red" />;
      case false:
        return <Icon kind="checkmarkCircle" className="tw-text-green" />;
      default:
        // unknown
        return <Icon kind="blank" />;
    }
  };

  return (
    <div className="tw-flex tw-mx-16 tw-gap-8 tw-items-center">
      {getIcon()}

      <span className="tw-w-24">{JOINT_NAMES[jointNumber]}</span>

      <GaugeContainer className="tw-flex-1">
        {isBraked === false && (
          <HoldableButton
            disabled={
              isDisabled ||
              jointAngle === undefined ||
              min === undefined ||
              jointAngle <= min
            }
            className="tw-rounded-6"
            onHold={() => onHoldSpinButton(false)}
            onRelease={stopGuidedMode}
          >
            <Icon kind="minus" />
          </HoldableButton>
        )}

        <Gauge
          value={jointAngle ?? 0}
          min={min ?? 0}
          max={max ?? 0}
          valueToString={(v) => `${v.toFixed(1)}`}
        />

        {isBraked === false && (
          <HoldableButton
            disabled={
              isDisabled ||
              jointAngle === undefined ||
              max === undefined ||
              jointAngle >= max
            }
            className="tw-rounded-6"
            onHold={() => onHoldSpinButton(true)}
            onRelease={stopGuidedMode}
          >
            <Icon kind="plus" />
          </HoldableButton>
        )}
      </GaugeContainer>

      {isBraked && (
        <Button
          variant="Filled"
          size={44}
          disabled={isDisabled}
          onClick={() => unbrakeJoint(jointNumber)}
          className="tw-rounded-10"
        >
          Unbrake
        </Button>
      )}

      <TorqueGauge
        disturbance={disturbance}
        collisionThreshold={collisionThreshold}
        maxTorque={maxTorque}
      />
    </div>
  );
}
