import { Cone } from '@react-three/drei';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';

import type { CartesianPose } from '@sb/geometry';

import { useVisualizerContext } from '../../VisualizerContext';

const CONE_RADIUS = 0.02;
const CONE_HEIGHT = 0.06;

export function CustomGripper() {
  const { onStateChange } = useVisualizerContext();

  const [tooltips, setTooltips] = useState<CartesianPose[]>([]);

  useEffect(() => {
    return onStateChange(
      (state) => {
        if (state.gripperState?.kind === 'CustomGripper') {
          return state.gripperState.tooltips.map((t) => t.tcpTransform);
        }

        return [];
      },
      isEqual,
      setTooltips,
    );
  }, [onStateChange]);

  return (
    <group rotation={[Math.PI / 2, 0, Math.PI]}>
      {tooltips.map((tooltip) => (
        <group
          position={[tooltip.x, tooltip.y, tooltip.z]}
          quaternion={[tooltip.i, tooltip.j, tooltip.k, tooltip.w]}
        >
          <Cone
            args={[CONE_RADIUS, CONE_HEIGHT]}
            position-z={-CONE_HEIGHT / 2}
            rotation-x={Math.PI / 2}
          >
            <meshStandardMaterial color="#d4d7d8" />
          </Cone>
        </group>
      ))}
    </group>
  );
}
