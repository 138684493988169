import { CommonQuaternions } from '@sb/geometry';

import { Axis } from './Axis';
import { useTooltipSource } from './useTooltipSource';

const X_AXIS_COLOR = '--standard-bots--x-color';
const Y_AXIS_COLOR = '--standard-bots--y-color';
const Z_AXIS_COLOR = '--standard-bots--z-color';

export function TooltipAxesBaseFrame() {
  const tooltip = useTooltipSource();

  return (
    <>
      <Axis
        source={tooltip}
        label="X"
        rotation={CommonQuaternions.ROTATE_NONE}
        absoluteRotation
        color={X_AXIS_COLOR}
      />
      <Axis
        source={tooltip}
        label="Y"
        rotation={CommonQuaternions.ROTATE_Z_90}
        absoluteRotation
        color={Y_AXIS_COLOR}
      />
      <Axis
        source={tooltip}
        label="Z"
        rotation={CommonQuaternions.ROTATE_Y_270}
        absoluteRotation
        color={Z_AXIS_COLOR}
      />
    </>
  );
}
