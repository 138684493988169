import cx from 'classnames';
import { Suspense, useState } from 'react';

import type { KinematicState } from '@sb/routine-runner';
import { Loader } from '@sb/ui/components';
import { six } from '@sb/utilities';
import type { AxesMode } from '@sb/visualizer';
import { VisualizerScene } from '@sb/visualizer';
import {
  useIsRobotConnected,
  useRobotFailureState,
  useRobotSafeguardState,
  useVisualizerChildren,
} from '@sbrc/hooks';

import { WaitForConfirmationModal } from '../notifications';

import { useFullPageVisualizer } from './useFullPageVisualizer';
import useOnVisualizerStateChange from './useOnVisualizerStateChange';

export const VISUALIZER_STATE_DEFAULT_POSITION: Partial<KinematicState> = {
  jointAngles: six(0),
};

interface RobotVisualizerProps {
  className?: string;
  isVizbot: boolean;
  robotID: string;
  axesMode?: AxesMode;
  showOtherRobotGhost?: boolean;
  ghostRobotState?: Partial<KinematicState>;
  isFullPage?: boolean;
}

export default function RobotVisualizer({
  className,
  isVizbot,
  robotID,
  axesMode,
  showOtherRobotGhost,
  ghostRobotState,
  isFullPage,
}: RobotVisualizerProps) {
  const isRobotConnected = useIsRobotConnected({ isVizbot });
  const safeguardState = useRobotSafeguardState({ isVizbot });
  const failureState = useRobotFailureState({ isVizbot });

  const [containerElement, setContainerElement] =
    useState<HTMLDivElement | null>(null);

  const isPositionUnknown = !isRobotConnected || safeguardState === 'eStop';

  const onStateChange = useOnVisualizerStateChange({ isVizbot });

  useFullPageVisualizer({
    containerStyle: containerElement?.style,
    isFullPage,
  });

  const otherRobotOnStateChange = useOnVisualizerStateChange({
    isVizbot: !isVizbot,
  });

  const onGhostRobotStateChange = (() => {
    if (isPositionUnknown || ghostRobotState) {
      return onStateChange;
    }

    if (showOtherRobotGhost) {
      return otherRobotOnStateChange;
    }

    return undefined;
  })();

  const { visualizerChildren } = useVisualizerChildren();

  const getAxesMode = ({ isHidden = false }): AxesMode => {
    if (isHidden) {
      return null;
    }

    if (axesMode) {
      return axesMode;
    }

    if (failureState?.recoveryType === 'ManualRecoveryMode') {
      return 'joints';
    }

    return null;
  };

  return (
    <div
      ref={setContainerElement}
      className={cx(
        isFullPage ? 'tw-fixed' : 'tw-relative',
        'tw-w-full',
        'tw-h-full',
        'tw-inset-0',
        'tw-surface-primary',
        className,
      )}
    >
      <Suspense
        fallback={
          <Loader
            className={cx(
              'tw-absolute',
              'tw-left-1/2',
              'tw-top-1/2',
              '-tw-translate-x-1/2',
              '-tw-translate-y-1/2',
            )}
          />
        }
      >
        <VisualizerScene
          onStateChange={isPositionUnknown ? undefined : onStateChange}
          axesMode={getAxesMode({ isHidden: !!ghostRobotState })}
          onGhostRobotStateChange={onGhostRobotStateChange}
          ghostRobotState={isPositionUnknown ? undefined : ghostRobotState}
          ghostRobotAxesMode={getAxesMode({ isHidden: !ghostRobotState })}
        >
          {visualizerChildren}
        </VisualizerScene>
      </Suspense>

      <WaitForConfirmationModal
        isVizbot
        robotID={robotID}
        shouldOpenAutomatically
      />
    </div>
  );
}
