import { useMemo, useLayoutEffect, useRef } from 'react';
import { BufferGeometry, Vector3, ShaderMaterial, Color, Matrix4 } from 'three';
import type { InstancedMesh } from 'three';

// How much area does our floor cover?
const FLOOR_SIZE = 25;

// How many rows and columns are there in our floor?
const FLOOR_DIVISIONS = 50;

// Base color for line and dots
const GRAY = 'rgb(15, 14, 13)';

// Initial opacities
const LINE_OPACITY = 0.16;
const DOT_OPACITY = 0.25;

// Where do we start reducing opacity?
const FADE_START = 1;

// Where do we reach 0 opacity?
const FADE_END = 8;

const radialFadeShader = new ShaderMaterial({
  uniforms: {
    color: { value: new Color('hotpink') },
    opacity: { value: 1.0 },
  },
  vertexShader: `
    varying vec3 vWorldPosition;

    void main() {
      #ifdef USE_INSTANCING
        gl_Position = projectionMatrix * viewMatrix * modelMatrix * instanceMatrix * vec4(position, 1.0);
        vWorldPosition = (modelMatrix * instanceMatrix * vec4(position.xyz, 1.0)).xyz;
      #else
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        vWorldPosition = (modelMatrix * vec4(position.xyz, 1.0)).xyz;
      #endif
    }
  `,
  fragmentShader: `
    uniform vec3 color;
    uniform float opacity;
    varying vec3 vWorldPosition;
    void main() {
      float distance = length(vWorldPosition.xz);
      // unlerp between fade start and end
      float t = smoothstep(${FADE_START}., ${FADE_END}., distance);
      gl_FragColor = vec4(color, (1. - t) * opacity);
    }
  `,
  transparent: true,
  depthWrite: false,
});

const lineMaterial = radialFadeShader.clone();
lineMaterial.uniforms.color = { value: new Color(GRAY) };
lineMaterial.uniforms.opacity = { value: LINE_OPACITY };

const sphereMaterial = radialFadeShader.clone();
sphereMaterial.uniforms.color = { value: new Color(GRAY) };
sphereMaterial.uniforms.opacity = { value: DOT_OPACITY };

export function Floor() {
  // generate a grid
  const geometry = useMemo(() => {
    const points = [];

    for (let i = 0; i <= FLOOR_DIVISIONS; i += 1) {
      const gap = FLOOR_SIZE / FLOOR_DIVISIONS;
      // horizontal lines
      points.push(new Vector3(-FLOOR_SIZE / 2 + i * gap, 0, -FLOOR_SIZE / 2));
      points.push(new Vector3(-FLOOR_SIZE / 2 + i * gap, 0, FLOOR_SIZE / 2));

      // vertical lines
      points.push(new Vector3(-FLOOR_SIZE / 2, 0, -FLOOR_SIZE / 2 + i * gap));
      points.push(new Vector3(FLOOR_SIZE / 2, 0, -FLOOR_SIZE / 2 + i * gap));
    }

    return new BufferGeometry().setFromPoints(points);
  }, []);

  const meshRef = useRef<InstancedMesh>(null);
  const sphereCount = FLOOR_DIVISIONS * FLOOR_DIVISIONS;

  useLayoutEffect(() => {
    if (meshRef === null) return;
    if (meshRef.current === null) return;

    const mesh = meshRef.current;

    const matrix = new Matrix4();

    const gap = FLOOR_SIZE / FLOOR_DIVISIONS;

    for (let i = 0; i < sphereCount; i += 1) {
      const x = (i % FLOOR_DIVISIONS) * gap - FLOOR_SIZE / 2;

      const z = Math.floor(i / FLOOR_DIVISIONS) * gap - FLOOR_SIZE / 2;

      matrix.setPosition(x, 0, z);
      mesh.setMatrixAt(i, matrix);
    }
  }, [sphereCount]);

  return (
    <>
      <lineSegments geometry={geometry} material={lineMaterial} />
      <instancedMesh
        ref={meshRef}
        args={[undefined, sphereMaterial, sphereCount]}
      >
        <sphereGeometry args={[0.0055, 4, 4]} />
      </instancedMesh>
    </>
  );
}
