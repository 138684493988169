import cx from 'classnames';
import { useEffect, useState } from 'react';

import { Button, GaugeStepperDualValue, Icon } from '@sb/design-system';
import {
  PAYLOAD_MASS_KG_ABSOLUTE_MAX,
  PAYLOAD_MASS_KG_ABSOLUTE_MIN,
  PAYLOAD_MASS_KG_DEFAULT,
} from '@sb/routine-runner';
import { massToString } from '@sb/utilities';
import {
  useRobotPayloadState,
  useRoutineRunnerHandle,
  useToast,
} from '@sbrc/hooks';
import { PAYLOAD_KG_SLIDER_STEP } from '@sbrc/utils';

interface ConfirmNoHumansAndPayloadProps {
  isConfirmed: boolean;
  setIsConfirmed: (isConfirmed: boolean) => void;
}

export function ConfirmNoHumansAndPayload({
  isConfirmed,
  setIsConfirmed,
}: ConfirmNoHumansAndPayloadProps) {
  const { setToast } = useToast();

  const routineRunnerHandle = useRoutineRunnerHandle({});

  const payloadKg = useRobotPayloadState({});

  const [targetPayload, setTargetPayload] = useState<number | undefined>();

  const isPayloadSaveDisabled =
    targetPayload === undefined || targetPayload === payloadKg;

  /**
   * When the payload "save" button is enabled, it means the target and actual payloads are divergent
   * and the user needs to save their changes before they can check there are no humans around the robot.
   */
  const isConfirmDisabled = !isPayloadSaveDisabled;

  useEffect(() => {
    // Uncheck the checkbox when it becomes disabled.
    if (isConfirmDisabled) {
      setIsConfirmed(false);
    }
  }, [isConfirmDisabled, setIsConfirmed]);

  const savePayload = async () => {
    if (isPayloadSaveDisabled) {
      return;
    }

    try {
      setToast({
        kind: 'progress',
        message: 'Setting payload...',
      });

      await routineRunnerHandle.setRobotPayload({ mass: targetPayload });
      setTargetPayload(undefined);

      setToast({
        kind: 'success',
        message: 'Payload successfully set',
      });
    } catch (error) {
      setToast({ kind: 'error', message: error.message });
    }
  };

  return (
    <>
      <fieldset className="tw-flex tw-flex-col">
        <h5 className="tw-heading-40">Live robot payload</h5>

        <GaugeStepperDualValue
          value={payloadKg ?? PAYLOAD_MASS_KG_DEFAULT}
          targetValue={isPayloadSaveDisabled ? undefined : targetPayload}
          onChange={setTargetPayload}
          min={PAYLOAD_MASS_KG_ABSOLUTE_MIN}
          max={PAYLOAD_MASS_KG_ABSOLUTE_MAX}
          step={PAYLOAD_KG_SLIDER_STEP}
          valueToString={massToString}
        >
          <Button
            className="tw-rounded-6"
            disabled={isPayloadSaveDisabled}
            onClick={savePayload}
          >
            Save
          </Button>
        </GaugeStepperDualValue>
      </fieldset>

      <label
        className={cx(
          'tw-flex',
          'tw-items-center',
          'tw-gap-16',
          isConfirmDisabled && 'tw-opacity-60',
        )}
      >
        <div
          className={cx(
            'tw-relative',
            'tw-flex-none',
            'tw-w-24',
            'tw-h-24',
            'tw-bg-fill-primary',
            'tw-rounded-6',
            !isConfirmDisabled && 'tw-border',
            isConfirmed ? 'tw-border-orange' : 'tw-border-label-quaternary',
            'tw-text-orange',
            'tw-flex',
            'tw-items-center',
            'tw-justify-center',
          )}
        >
          <input
            type="checkbox"
            checked={isConfirmed}
            onChange={(e) => setIsConfirmed(e.target.checked)}
            className={cx('tw-appearance-none', 'tw-opacity-0', 'tw-absolute')}
            disabled={isConfirmDisabled}
          />
          {isConfirmed && <Icon kind="checkmark" className="tw-icon-22" />}
        </div>

        <span className="tw-text-15">
          I confirm that no humans are within reach of the robot and that the
          payload is correct.
        </span>
      </label>
    </>
  );
}
