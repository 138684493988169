import { useMemo } from 'react';

import { Icon } from '@sb/design-system';
import type {
  RoutineRunningPauseDetails,
  RoutineRunningPauseKind,
} from '@sb/routine-runner';
import { useIsRobotBraked, useRobotPauseDetails } from '@sbrc/hooks';

import { WidgetView } from '../widget-panel';

import { PauseActionSection } from './PauseActionSection';
import { UnbrakeButton } from './UnbrakeButton';

const HIDE_WIDGET_FOR_KINDS: RoutineRunningPauseKind[] = [
  'guidedModeInvalidation',
  'user',
  'waitForConfirmation',
];

interface UsePausedWidgetArgs {
  isVizbot: boolean;
  robotID: string;
}

export function usePausedWidget({
  isVizbot,
  robotID,
}: UsePausedWidgetArgs): React.ReactNode {
  const pauseDetails = useRobotPauseDetails({ isVizbot });

  const isBraked = useIsRobotBraked({ isVizbot });

  const pauseDetail = useMemo<RoutineRunningPauseDetails | undefined>(() => {
    const filteredPauseDetails = pauseDetails.filter(
      (d) => !HIDE_WIDGET_FOR_KINDS.includes(d.kind),
    );

    return (
      filteredPauseDetails.find((d) => !d.isAutoResume) ??
      filteredPauseDetails[0]
    );
  }, [pauseDetails]);

  if (!pauseDetail) {
    return null;
  }

  return (
    <WidgetView className="tw-p-16 tw-gap-16">
      <Icon
        kind="pauseFill"
        className="tw-text-orange tw-mx-auto -tw-mb-4 tw-icon-42"
      />

      <div className="tw-flex tw-flex-col tw-gap-4 tw-text-15 tw-text-center">
        {pauseDetail.reason && <p>{pauseDetail.reason}</p>}

        <p className="tw-font-medium">The routine was paused automatically.</p>

        {!isBraked && pauseDetail?.isAutoResume && (
          <p>The routine will automatically resume.</p>
        )}
      </div>

      {isBraked && <UnbrakeButton isVizbot={isVizbot} />}

      {!isBraked && !pauseDetail?.isAutoResume && (
        <PauseActionSection
          pauseDetail={pauseDetail}
          robotID={robotID}
          isVizbot={isVizbot}
        />
      )}
    </WidgetView>
  );
}
