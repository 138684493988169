import cx from 'classnames';
import { clamp } from 'lodash';

interface TorqueGagueProps {
  disturbance?: number;
  collisionThreshold: number;
  maxTorque: number;
}

const MIN_PX = 12;
const MAX_PX = 38;
const MAX_FILLED_PX = 40;
const SPREAD_PX = MAX_PX - MIN_PX;

export function TorqueGauge({
  disturbance,
  collisionThreshold,
  maxTorque,
}: TorqueGagueProps) {
  const absDisturbance = Math.abs(disturbance ?? 0);

  const filledHeight = clamp(
    MIN_PX + (absDisturbance / maxTorque) * SPREAD_PX,
    0,
    MAX_FILLED_PX,
  );

  const needleHeight = clamp(
    MIN_PX + (collisionThreshold / maxTorque) * SPREAD_PX,
    0,
    MAX_PX,
  );

  return (
    <div
      className={cx(
        'tw-w-16',
        'tw-h-44',
        'tw-rounded-10',
        'tw-surface-elevated',
        'tw-shadow-30',
        'tw-grid',
        'tw-justify-items-center',
        'tw-overflow-hidden',
        'tw-pb-2',
      )}
    >
      {disturbance !== undefined && (
        <div
          style={{ height: `${filledHeight}px` }}
          className={cx(
            'tw-self-end',
            'tw-col-start-1',
            'tw-row-start-1',
            'tw-w-12',
            'tw-rounded-6',
            absDisturbance > collisionThreshold ? 'tw-bg-red' : 'tw-bg-green',
          )}
        />
      )}

      <div
        style={{ height: `${needleHeight}px` }}
        className={cx(
          'tw-self-end',
          'tw-col-start-1',
          'tw-row-start-1',
          'tw-w-16',
          'tw-border-t-[2px]',
          'tw-border-label-secondary',
        )}
      />
    </div>
  );
}
