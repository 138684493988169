import { Circle } from '@react-three/drei';
import { useLoader } from '@react-three/fiber';
import { useMemo } from 'react';
import type { ColorRepresentation } from 'three';
import { DoubleSide, Mesh, MeshStandardMaterial } from 'three';
import { STLLoader } from 'three-stdlib';

import rotationArrowPath from './RotationArrow.stl';

interface RotationArrowProps {
  reverseArrow?: boolean;
  position: number;
  color: string;
}

export function RotationArrow({
  reverseArrow,
  position,
  color,
}: RotationArrowProps) {
  const geometry = useLoader(STLLoader, rotationArrowPath);

  const object = useMemo(() => {
    const hexColor = getComputedStyle(document.body)
      .getPropertyValue(color)
      .trim() as ColorRepresentation;

    return new Mesh(
      geometry,
      new MeshStandardMaterial({
        color: hexColor,
        emissive: '#555',
      }),
    );
  }, [geometry, color]);

  return (
    <>
      <primitive
        object={object}
        scale={[0.01, 0.02, 0.01]}
        position={[position, 0.0, 0.009]}
        rotation-z={reverseArrow ? -Math.PI / 2 : Math.PI / 2}
      />
      <Circle
        args={[0.06, 32]}
        material-color="white"
        material-side={DoubleSide}
        position={[position, 0, 0]}
        rotation={[0, Math.PI / 2, Math.PI / 2]}
        material-transparent
        material-opacity={0.6}
        material-depthWrite={false}
      />
    </>
  );
}
