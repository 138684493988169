import type { Routine } from '@sb/remote-control/types';

import GoToRoutineButton from '../GoToRoutineButton';
import GoToSceneViewButton from '../GoToSceneViewButton';
import StopRoutineButton from '../StopRoutineButton';

interface RoutineRunningWidgetProps {
  routine: Routine.ConvertedResponse;
}

export function RoutineRunningWidget({ routine }: RoutineRunningWidgetProps) {
  return (
    <div className="tw-flex tw-flex-col tw-gap-8">
      <GoToRoutineButton
        routineID={String(routine.id)}
        routineName={String(routine.name)}
      />
      <GoToSceneViewButton />
      <StopRoutineButton />
    </div>
  );
}
