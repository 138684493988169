import { useState } from 'react';

import { Icon } from '@sb/design-system';
import { FailureKind } from '@sb/routine-runner';
import { useRobotFailureState } from '@sbrc/hooks';

import { WidgetView } from '../../widget-panel';
import { ConfirmNoHumansAndPayload } from '../ConfirmNoHumansAndPayload';

import { ManualRecoveryWidget } from './manual-recovery/ManualRecoveryWidget';
import { RecoverButton } from './RecoverButton';

interface RecoveryWidgetProps {
  robotID: string;
  isVizbot: boolean;
}

export function RecoveryWidget({ robotID, isVizbot }: RecoveryWidgetProps) {
  const failureState = useRobotFailureState({ isVizbot });

  const [isNoHumansConfirmed, setIsNoHumansConfirmed] = useState(false);

  if (!failureState) {
    return null;
  }

  const introMessage = (
    <>
      <p className="tw-text-15 tw-mt-2 tw-text-center">
        {isVizbot ? 'Visualizer' : 'Live Robot'} has reported an error.
      </p>

      <p className="tw-text-15 tw-mt-8 tw-text-center">
        {failureState.failureReason}
      </p>
    </>
  );

  if (failureState.recoveryType === 'ManualRecoveryMode') {
    return (
      <ManualRecoveryWidget
        robotID={robotID}
        isVizbot={isVizbot}
        introMessage={introMessage}
      />
    );
  }

  const isCollisionFailureKind =
    failureState.failure.kind === FailureKind.CollisionFailure ||
    failureState.failure.kind === FailureKind.TorqueCollisionFailure;

  const showSafetyConfirmation = !isVizbot && isCollisionFailureKind;

  return (
    <WidgetView className="tw-p-16 tw-gap-16">
      <Icon
        kind="exclamationTriangle"
        className="tw-text-mango tw-mx-auto tw-icon-42"
      />

      <div>
        <h3 className="tw-font-medium tw-text-center">Robot needs recovery.</h3>

        <p className="tw-text-15 tw-mt-2 tw-text-center">
          {isVizbot ? 'Visualizer' : 'Live Robot'} has reported an error.
        </p>

        <p className="tw-text-15 tw-mt-8 tw-text-center">
          {failureState.failureReason}
        </p>
      </div>

      {showSafetyConfirmation && (
        <ConfirmNoHumansAndPayload
          isConfirmed={isNoHumansConfirmed}
          setIsConfirmed={setIsNoHumansConfirmed}
        />
      )}

      <RecoverButton
        recoveryType={failureState.recoveryType}
        isVizbot={isVizbot}
        isDisabled={!isNoHumansConfirmed && showSafetyConfirmation}
      />
    </WidgetView>
  );
}
