import { sumBy } from 'lodash';

import { Icon, NavigationBar, NavigationBarButton } from '@sb/design-system';
import type { ArmJointTorques } from '@sb/motion-planning';
import { JOINT_NUMBERS } from '@sb/motion-planning';
import { WidgetView } from '@sbrc/components/visualizer-view-shared/widget-panel';
import {
  useRobotJointAnglesDegrees,
  useRobotJointBrakesEngaged,
  useRobotJointDisturbances,
  useRobotJointLimitsDegrees,
  useRobotSafeguardState,
} from '@sbrc/hooks';

import { RecoverButton } from '../RecoverButton';

import { JointRow } from './JointRow';

interface RecoveryMainViewProps {
  isVizbot: boolean;
  collisionThresholds: ArmJointTorques;
  onHelp: () => void;
}

export function RecoveryMainView({
  isVizbot,
  collisionThresholds,
  onHelp,
}: RecoveryMainViewProps) {
  const isEstopped = useRobotSafeguardState({ isVizbot }) === 'eStop';
  const jointBrakesEngaged = useRobotJointBrakesEngaged({ isVizbot });
  const jointAnglesDegrees = useRobotJointAnglesDegrees({ isVizbot });
  const jointLimitsDegrees = useRobotJointLimitsDegrees({ isVizbot });
  const jointDisturbances = useRobotJointDisturbances({ isVizbot });

  const requireRecoveryCount = sumBy(JOINT_NUMBERS, (jointNumber) =>
    jointBrakesEngaged?.[jointNumber] === true ||
    Math.abs(jointDisturbances?.[jointNumber] ?? 0) >
      collisionThresholds[jointNumber]
      ? 1
      : 0,
  );

  const isExitDisabled =
    !jointBrakesEngaged || !jointAnglesDegrees || requireRecoveryCount > 0;

  return (
    <WidgetView>
      <NavigationBar
        className="tw-pl-8 tw-pr-8"
        contentRight={
          <NavigationBarButton onClick={onHelp}>
            <Icon kind="questionmarkCircle" />
          </NavigationBarButton>
        }
      >
        Recovery
      </NavigationBar>

      <div className="tw-flex-1 tw-flex tw-flex-col tw-gap-16 tw-overflow-auto tw-pt-8">
        <h5 className="tw-font-medium tw-text-13 tw-text-label-tertiary tw-flex tw-px-16 tw-justify-between">
          <span>Brake status</span>
          <span>Torque</span>
        </h5>

        {JOINT_NUMBERS.map((jointNumber) => (
          <JointRow
            key={jointNumber}
            isVizbot={isVizbot}
            isDisabled={isEstopped}
            jointNumber={jointNumber}
            isBraked={jointBrakesEngaged?.[jointNumber]}
            jointAngle={jointAnglesDegrees?.[jointNumber]}
            min={jointLimitsDegrees?.[jointNumber].min}
            max={jointLimitsDegrees?.[jointNumber].max}
            disturbance={jointDisturbances?.[jointNumber]}
            collisionThreshold={collisionThresholds[jointNumber]}
            maxTorque={Math.max(...collisionThresholds)}
          />
        ))}
      </div>

      <div className="tw-flex tw-flex-col tw-gap-16 tw-p-16">
        <p className="tw-text-15 tw-text-center tw-text-label-tertiary">
          {requireRecoveryCount === 0 && 'All joints recovered'}
          {requireRecoveryCount === 1 && '1 joint needs recovery'}
          {requireRecoveryCount > 1 &&
            `${requireRecoveryCount} joints need recovery`}
        </p>

        <RecoverButton
          recoveryType="ManualRecoveryMode"
          isVizbot={isVizbot}
          isDisabled={isExitDisabled}
        />
      </div>
    </WidgetView>
  );
}
