import { useVisualizerContext } from '../VisualizerContext';

import { TooltipAxesBaseFrame } from './TooltipAxesBaseFrame';
import { TooltipAxesTooltipFrame } from './TooltipAxesTooltipFrame';

export function TooltipAxes() {
  const { axesMode } = useVisualizerContext();

  return (
    <>
      {axesMode === 'robotArm' && <TooltipAxesBaseFrame />}
      {axesMode === 'tooltip' && <TooltipAxesTooltipFrame />}
    </>
  );
}
