import { useEffect, useState } from 'react';

import type { ArmJointTorques } from '@sb/motion-planning';
import {
  DEFAULT_TORQUE_SHOCK_COLLISION_THRESHOLDS,
  JOINT_NUMBERS,
} from '@sb/motion-planning';
import { onGetSafetySettings } from '@sbrc/services';

export function useCollisionThresholds(robotID: string): ArmJointTorques {
  const [collisionThresholds, setCollisionThresholds] =
    useState<ArmJointTorques>(DEFAULT_TORQUE_SHOCK_COLLISION_THRESHOLDS);

  useEffect(() => {
    const unsubscribe = onGetSafetySettings(robotID, (safetySettings) => {
      setCollisionThresholds(
        JOINT_NUMBERS.map(
          (jointNumber) =>
            safetySettings.collisionThresholds[jointNumber] ??
            DEFAULT_TORQUE_SHOCK_COLLISION_THRESHOLDS[jointNumber],
        ) as ArmJointTorques,
      );
    });

    return () => unsubscribe();
  }, [robotID]);

  return collisionThresholds;
}
