import cx from 'classnames';
import { useState } from 'react';

import { Button, Icon } from '@sb/design-system';
import type { RoutineRunningPauseDetails } from '@sb/routine-runner';
import { usePlayRobot, useStopRobot } from '@sbrc/hooks';

interface PauseActionSectionProps {
  pauseDetail: RoutineRunningPauseDetails;
  isVizbot: boolean;
  robotID: string;
}

export function PauseActionSection({
  pauseDetail,
  isVizbot,
  robotID,
}: PauseActionSectionProps) {
  const [isNoCollisionConfirmed, setIsNoCollisionConfirmed] = useState(false);

  const { stopRobot } = useStopRobot({ isVizbot });

  const { onPlay } = usePlayRobot({ robotID, isVizbot });

  return pauseDetail.kind === 'collision' ? (
    <>
      <label className={cx('tw-flex', 'tw-items-center', 'tw-gap-16')}>
        <div
          className={cx(
            'tw-relative',
            'tw-flex-none',
            'tw-w-24',
            'tw-h-24',
            'tw-bg-fill-primary',
            'tw-rounded-6',
            'tw-border',
            isNoCollisionConfirmed
              ? 'tw-border-orange'
              : 'tw-border-label-quaternary',
            'tw-text-orange',
            'tw-flex',
            'tw-items-center',
            'tw-justify-center',
          )}
        >
          <input
            type="checkbox"
            checked={isNoCollisionConfirmed}
            onChange={(e) => setIsNoCollisionConfirmed(e.target.checked)}
            className={cx('tw-appearance-none', 'tw-opacity-0', 'tw-absolute')}
          />
          {isNoCollisionConfirmed && (
            <Icon kind="checkmark" className="tw-icon-22" />
          )}
        </div>

        <span className="tw-text-15">
          I have checked for collisions and want to resume the routine.
        </span>
      </label>

      <Button
        onClick={() => {
          onPlay();
          setIsNoCollisionConfirmed(false);
        }}
        variant="Filled"
        className="tw-rounded-6 tw-mt-4"
        disabled={!isNoCollisionConfirmed}
      >
        <Icon kind="play" />
        <span>Resume routine</span>
      </Button>

      <Button onClick={() => stopRobot()} className="tw-rounded-6 tw-mt-4">
        <span>Stop routine</span>
      </Button>
    </>
  ) : (
    <Button
      onClick={() => {
        onPlay();
      }}
      variant="Filled"
      className="tw-rounded-6 tw-mt-4"
    >
      <Icon kind="play" />
      <span>Resume routine</span>
    </Button>
  );
}
