import {
  COLLABORATIVE_MAX_JOINT_ACCELERATIONS,
  COLLABORATIVE_MAX_JOINT_SPEEDS,
} from '@sb/motion-planning';
import type { SpeedProfile } from '@sb/routine-runner';
import { getRobot, getSafetySettings } from '@sbrc/services';
import { calculateSpeedProfile } from '@sbrc/utils';

const ADHOC_SPEED_PROFILE: SpeedProfile = {
  maxTooltipSpeed: 0.25,
  maxJointSpeeds: COLLABORATIVE_MAX_JOINT_SPEEDS,
  maxJointAccelerations: COLLABORATIVE_MAX_JOINT_ACCELERATIONS,
};

export default async function getAdHocSpeedProfile(
  robotID: string,
  /* isn't currently controlling anything but might be needed */
  _isVizbot: boolean,
  isFullSpeed?: boolean,
): Promise<SpeedProfile> {
  const robot = await getRobot(robotID);

  const safetySettings = await getSafetySettings(robotID);

  return calculateSpeedProfile({
    safetySettings,
    speedRestrictionPercentage: robot?.speedRestrictionPercentage,
    additionalSpeedProfile: isFullSpeed ? undefined : ADHOC_SPEED_PROFILE,
  });
}
