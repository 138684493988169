import type { Robot, Routine } from '@sb/remote-control/types';
import {
  useIsRobotBraked,
  useIsRobotConnected,
  useRobotFailureState,
  useRobotStateKind,
} from '@sbrc/hooks';

import { ActionRequiredWidget } from './ActionRequiredWidget';
import { AntigravityMode } from './AntigravityMode';
import { DisconnectedWidget } from './DisconnectedWidget';
import { RecoveryWidget } from './recovery-widget/RecoveryWidget';
import { RobotBrakedWidget } from './RobotBrakedWidget';
import { RoutineRunningWidget } from './RoutineRunningWidget';
import { usePausedWidget } from './usePausedWidget';

interface UseWarningWidgetArgs {
  isVizbot: boolean;
  robot: Robot.ConvertedResponse;
  routine?: Routine.ConvertedResponse | null;
  isMoveView?: boolean;
}

export function useWarningWidget({
  isVizbot,
  robot,
  routine,
  isMoveView,
}: UseWarningWidgetArgs): React.ReactNode {
  const isConnected = useIsRobotConnected({ isVizbot });

  const routineRunnerStateKind = useRobotStateKind({ isVizbot });

  const pausedWidget = usePausedWidget({ isVizbot, robotID: robot.id });

  const isBraked = useIsRobotBraked({ isVizbot });

  const failureState = useRobotFailureState({ isVizbot });

  if (!isConnected) {
    return <DisconnectedWidget robot={robot} isVizbot={isVizbot} />;
  }

  if (pausedWidget) {
    return pausedWidget;
  }

  if (failureState) {
    return <RecoveryWidget robotID={robot.id} isVizbot={isVizbot} />;
  }

  if (!isMoveView && routine?.isActionRequired) {
    return <ActionRequiredWidget robot={robot} />;
  }

  if (isBraked) {
    return <RobotBrakedWidget isVizbot={isVizbot} />;
  }

  if (routineRunnerStateKind === 'Antigravity') {
    return <AntigravityMode />;
  }

  if (routineRunnerStateKind === 'AntigravitySlow') {
    return <AntigravityMode slow />;
  }

  if (isMoveView && routine && routineRunnerStateKind === 'RoutineRunning') {
    return <RoutineRunningWidget routine={routine} />;
  }

  return null;
}
