import cx from 'classnames';

import styles from './WidgetPanel.module.css';

interface WidgetPanelProps {
  position: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
  children: React.ReactNode;
  isHidden?: boolean;
}

const WidgetPanel = ({ children, position, isHidden }: WidgetPanelProps) => {
  const verticalPosition =
    position === 'top-left' || position === 'top-right' ? 'top' : 'bottom';

  const horizontalPostion =
    position === 'top-left' || position === 'bottom-left' ? 'left' : 'right';

  return (
    <div
      className={cx(
        styles.widgetPanel,
        'tw-z-30',
        styles[verticalPosition],
        styles[horizontalPostion],
      )}
      hidden={isHidden}
    >
      {children}
    </div>
  );
};

export default WidgetPanel;
