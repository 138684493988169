import { isEqual } from 'lodash';
import { useEffect, useRef } from 'react';
import type { Group } from 'three';

import { CommonQuaternions } from '@sb/geometry';

import { RobotPart } from '../../RobotPart';
import type { GetGripperState } from '../../types';
import { useVisualizerContext } from '../../VisualizerContext';

import bodPath from './2FG7_BOD.glb';
import fgtPath from './2FG7_FGT_16-5.glb';

const GET_GRIPPER_STATE_DEFAULT: GetGripperState = (state) =>
  state.gripperState;

interface OnRobot2FG7Props {
  getGripperState?: GetGripperState;
}

export function OnRobot2FG7({
  getGripperState = GET_GRIPPER_STATE_DEFAULT,
}: OnRobot2FG7Props) {
  const { onStateChange } = useVisualizerContext();

  const finger1ref = useRef<Group>(null!);
  const finger2ref = useRef<Group>(null!);

  useEffect(() => {
    return onStateChange(
      (state) => {
        const gripperState = getGripperState(state);

        return gripperState?.kind === 'OnRobot2FG7'
          ? ([
              gripperState.fingerOrientation,
              gripperState.fingertipOffset,
              gripperState.width.inner,
            ] as const)
          : [];
      },
      isEqual,
      ([fingerOrientation, fingertipOffset, width]) => {
        if (!fingerOrientation) {
          finger1ref.current.visible = false;
          finger2ref.current.visible = false;
        } else {
          finger1ref.current.visible = true;
          finger2ref.current.visible = true;

          let xOffset = width / 2;
          let rotate1 = CommonQuaternions.ROTATE_NONE;
          let rotate2 = CommonQuaternions.ROTATE_Y_180;

          if (fingerOrientation === 'outward') {
            xOffset += fingertipOffset;
            // rotate the fingers 180°
            [rotate2, rotate1] = [rotate1, rotate2];
          }

          finger1ref.current.position.set(xOffset, -0.1, 0);
          finger2ref.current.position.set(-xOffset, -0.1, 0);

          finger1ref.current.setRotationFromQuaternion(rotate1);
          finger2ref.current.setRotationFromQuaternion(rotate2);
        }
      },
    );
  }, [onStateChange, getGripperState]);

  return (
    <>
      <RobotPart path={bodPath} />
      <group ref={finger1ref}>
        <RobotPart path={fgtPath} />
      </group>
      <group ref={finger2ref}>
        <RobotPart path={fgtPath} />
      </group>
    </>
  );
}
