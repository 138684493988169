import Image from 'next/image';

import { STANDARD_BOTS_ANTIGRAVITY_SRC } from '@sbrc/utils/constants';

import { WidgetView } from '../widget-panel';

interface AntigravityModeProps {
  slow?: boolean;
}

export function AntigravityMode({ slow = false }: AntigravityModeProps) {
  const fineTuneNote = slow ? (
    <p>
      <strong>"Fine Tune"</strong> mode active.
    </p>
  ) : (
    <p>
      Double press and hold wrist button for <strong>"Fine Tune"</strong> mode.
    </p>
  );

  return (
    <WidgetView className="tw-p-16 tw-gap-16">
      <p className="tw-text-15 tw-text-center">
        <strong>Robot is in antigravity mode.</strong> To move the robot, please
        hold the “Antigravity” button located on the robot's wrist and drag the
        arm to the desired position. Releasing the button will stop antigravity
        mode.
      </p>

      {fineTuneNote}

      <Image
        src={STANDARD_BOTS_ANTIGRAVITY_SRC}
        width={290}
        height={110}
        alt="Antigravity robot icon"
      />
    </WidgetView>
  );
}
