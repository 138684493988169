import { useRouter } from 'next/router';

import { Button, Typography } from '@sb/ui/components';
import { OpenIcon } from '@sb/ui/icons';
import { getLinkToRoutinePage } from '@sbrc/utils';

interface GoToRoutineButtonProps {
  className?: string;
  routineID: string;
  routineName: string;
}

const GoToRoutineButton = ({
  className,
  routineID,
  routineName,
}: GoToRoutineButtonProps) => {
  const { push } = useRouter();

  const onGoToRoutineClick = () => {
    push(getLinkToRoutinePage(routineID));
  };

  return (
    <Button
      className={className}
      buttonChildrenClassName="tw-flex-1 tw-overflow-hidden"
      isFullWidth
      onClick={onGoToRoutineClick}
      endIcon={<OpenIcon />}
      variant="grayDark"
    >
      <Typography variant="medium" hasNoWrap>
        <strong>Routine:</strong> {routineName}
      </Typography>
    </Button>
  );
};

export default GoToRoutineButton;
