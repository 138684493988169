import { useThree } from '@react-three/fiber';
import { useEffect, useMemo } from 'react';
import { Object3D } from 'three';

import type { CartesianPose } from '@sb/geometry';
import { isApproximatelyEqual } from '@sb/utilities';

import { useVisualizerContext } from '../VisualizerContext';

const POSE_KEYS: Array<keyof CartesianPose> = [
  'i',
  'j',
  'k',
  'w',
  'x',
  'y',
  'z',
];

// If any element of the pose changed more than the epsilon value then trigger a render
const POSE_EPSILON = 0.001;

export function useTooltipSource() {
  const { onStateChange } = useVisualizerContext();

  const tooltipSource = useMemo(() => new Object3D(), []);

  const invalidate = useThree((state) => state.invalidate);

  useEffect(() => {
    return onStateChange(
      (state) => state.tooltipPoint,

      (valuesA, valuesB) =>
        POSE_KEYS.every((poseKey) =>
          isApproximatelyEqual(
            valuesA[poseKey],
            valuesB[poseKey],
            POSE_EPSILON,
          ),
        ),

      (tooltipPoint) => {
        tooltipSource.position.set(
          tooltipPoint.x,
          tooltipPoint.y,
          tooltipPoint.z,
        );

        tooltipSource.quaternion.set(
          tooltipPoint.i,
          tooltipPoint.j,
          tooltipPoint.k,
          tooltipPoint.w,
        );

        invalidate();
      },
    );
  }, [tooltipSource, onStateChange, invalidate]);

  return tooltipSource;
}
