import { useRouter } from 'next/router';

import { Button, Icon } from '@sb/design-system';
import type { Robot } from '@sb/remote-control/types';
import { getLinkToRoutinePage } from '@sbrc/utils';

import { WidgetView } from '../widget-panel';

interface ActionRequiredWidgetProps {
  robot: Robot.ConvertedResponse;
}

export function ActionRequiredWidget({ robot }: ActionRequiredWidgetProps) {
  const { push } = useRouter();

  const routineID = robot.latestRoutineID;

  const goToRoutineEditor = () => {
    if (!routineID) return;

    push(getLinkToRoutinePage(routineID));
  };

  return (
    <WidgetView className="tw-p-16 tw-gap-16">
      <Icon
        kind="exclamationTriangle"
        className="tw-text-mango tw-mx-auto tw-icon-42"
      />

      <div>
        <h3 className="tw-font-medium tw-text-center">Action required.</h3>

        <p className="tw-text-15 tw-mt-2 tw-text-center">
          One or more steps need to be fixed before the robot can completely
          play this routine. To update or confirm the step settings for the
          robot, please tap “Go to routine editor”.
        </p>
      </div>

      <Button
        onClick={goToRoutineEditor}
        variant="Filled"
        className="tw-rounded-6"
      >
        Go to routine editor
      </Button>
    </WidgetView>
  );
}
