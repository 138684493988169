import { Arm } from './arm/Arm';
import { ArmBase } from './armBase/ArmBase';
import { TooltipAxes } from './axes/TooltipAxes';
import { EndEffector } from './endEffector/EndEffector';

export function Robot() {
  return (
    <group>
      <ArmBase>
        <Arm>
          <EndEffector />
        </Arm>
      </ArmBase>
      <TooltipAxes />
    </group>
  );
}
