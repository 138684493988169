import { NavigationBar, NavigationBarBackButton } from '@sb/design-system';
import { WidgetView } from '@sbrc/components/visualizer-view-shared/widget-panel';

interface RecoveryHelpViewProps {
  onBack: () => void;
}

export function RecoveryHelpView({ onBack }: RecoveryHelpViewProps) {
  return (
    <WidgetView>
      <NavigationBar
        className="tw-pl-8 tw-pr-8"
        contentLeft={
          <NavigationBarBackButton onClick={onBack}>
            Recovery
          </NavigationBarBackButton>
        }
      >
        Tips
      </NavigationBar>

      <div className="tw-flex-1 tw-flex tw-flex-col tw-p-16 tw-gap-16 tw-border-t tw-border-divider-primary tw-overflow-auto">
        <p>
          To recover from situations where the robot is stuck, you must get all
          joints in a state where they do not register a collision and succeed
          in unbraking.
        </p>
        <p>If this fails, two next steps are:</p>
        <ol className="tw-list-decimal tw-pl-24 tw-flex tw-flex-col tw-gap-16">
          <li>
            <span className="tw-font-medium">Use Brake Release Pins:</span>{' '}
            Refer to “Movement Without Drive Power” in the RO1 user manual.
          </li>
          <li>
            <span className="tw-font-medium">Adjust Collision Settings:</span>{' '}
            If the collision settings are too sensitive, you may adjust them in
            Safety Settings.
          </li>
        </ol>

        <p>
          <span className="tw-font-medium">Need help from a human?</span>
          <br />
          1-888-9-ROBOTS
          <br />
          support@standardbots.com
        </p>
      </div>
    </WidgetView>
  );
}
