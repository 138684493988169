import { Html } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import { useEffect, useMemo, useRef, useState } from 'react';
import type { ColorRepresentation, Group, Object3D, Quaternion } from 'three';
import { BufferGeometry, Line, LineBasicMaterial, Vector3 } from 'three';

import { RotationArrow } from './RotationArrow';

import styles from './Axis.module.css';

const AXIS_SIZE = 0.5;
const ARROW_POSITION = AXIS_SIZE * 0.8;
const LABEL_POSITION = AXIS_SIZE + 0.07;

// run this useFrame before others, so that the <Html> component receives the correct position
const RENDER_PRIORITY = -1;

interface AxisProps {
  source: Object3D;
  rotation: Quaternion;
  absoluteRotation?: boolean;
  label: string;
  reverseArrow?: boolean;
  color: string;
}

export function Axis({
  source,
  rotation,
  absoluteRotation,
  label,
  reverseArrow,
  color,
}: AxisProps) {
  const ref = useRef<Group>(null!);

  useFrame(() => {
    source.getWorldPosition(ref.current.position);

    if (absoluteRotation) {
      ref.current.quaternion.copy(rotation);
    } else {
      source.getWorldQuaternion(ref.current.quaternion);
      ref.current.quaternion.multiply(rotation);
    }
  }, RENDER_PRIORITY);

  const axis = useMemo(() => {
    const geometry = new BufferGeometry().setFromPoints([
      new Vector3(0, 0, 0),
      new Vector3(AXIS_SIZE, 0, 0),
    ]);

    const hexColor = getComputedStyle(document.body)
      .getPropertyValue(color)
      .trim() as ColorRepresentation;

    const material = new LineBasicMaterial({ color: hexColor });

    return new Line(geometry, material);
  }, [color]);

  // need to delay showing HTML labels otherwise they fail occasionally
  const invalidate = useThree((state) => state.invalidate);
  const [showLabels, setShowLabels] = useState(false);

  useEffect(() => {
    let isCancelled = false;

    setTimeout(() => {
      if (!isCancelled) {
        setShowLabels(true);
        invalidate();
      }
    }, 200);

    return () => {
      isCancelled = true;
    };
  }, [invalidate]);

  return (
    <group ref={ref}>
      <primitive object={axis} />
      <RotationArrow
        reverseArrow={reverseArrow}
        position={ARROW_POSITION}
        color={color}
      />
      {showLabels && (
        <Html
          transform
          sprite
          distanceFactor={1}
          position={[LABEL_POSITION, 0, 0]}
          className={styles.label}
          style={{ backgroundColor: `var(${color})` }}
          pointerEvents="none"
          occlude="blending"
          geometry={<sphereGeometry args={[0.062]} />}
        >
          {label}
        </Html>
      )}
    </group>
  );
}
