import { useState } from 'react';

import { RecoveryHelpView } from './RecoveryHelpView';
import { RecoveryIntroView } from './RecoveryIntroView';
import { RecoveryMainView } from './RecoveryMainView';
import { useCollisionThresholds } from './useCollisionThresholds';

interface ManualRecoveryWidgetProps {
  robotID: string;
  isVizbot: boolean;
  introMessage: React.ReactNode;
}

type RecoveryView = 'intro' | 'main' | 'help';

export function ManualRecoveryWidget({
  robotID,
  isVizbot,
  introMessage,
}: ManualRecoveryWidgetProps) {
  const [recoveryView, setRecoveryView] = useState<RecoveryView>('intro');
  const collisionThresholds = useCollisionThresholds(robotID);

  switch (recoveryView) {
    case 'intro':
      return (
        <RecoveryIntroView
          onStart={() => setRecoveryView('main')}
          introMessage={introMessage}
        />
      );

    case 'main':
      return (
        <RecoveryMainView
          isVizbot={isVizbot}
          collisionThresholds={collisionThresholds}
          onHelp={() => setRecoveryView('help')}
        />
      );

    case 'help':
      return <RecoveryHelpView onBack={() => setRecoveryView('main')} />;

    default:
      recoveryView satisfies never;

      return null;
  }
}
